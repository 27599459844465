import './tags.scss';
import axiosInstance from '../axios';
import { useState, useEffect } from 'react';
import { Tag } from '../components';

const Tags = ({ chosenTags, setChosenTags }) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    axiosInstance.get('tags/').then((res)=>{
      setTags(res.data); 
    });
  }, []);

  return (
    <section className="tags-out">
      <div className="tags padding--all">

        <div className="section-title">Tags</div>

        <div className="tags__content">
          {
            Array.isArray(tags) && tags.map(tag => <Tag key={tag.name} tag_name={tag.name} chosenTags={chosenTags} setChosenTags={setChosenTags} />)
          }
        </div>
        
      </div>
    </section>
  );
};

export default Tags;
import { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import { Tag } from '../components';
import './more.scss';

const More = ({ blogTags, slug }) => {
  
  const [articles, setArticles] = useState([]);
  const [chosenTags, setChosenTags] = useState(new Set());

  useEffect(() => {
    if (blogTags) {
      const tagStr = [...blogTags].join('&tags=');
      axiosInstance.get(`posts/?tags=★ Featured&tags=${tagStr}`).then(repos => {
        let moreArticles = repos.data.results.sort(() => (Math.random()-0.5)).slice(0, 5);
        moreArticles = moreArticles.filter(article => article.slug !== slug).slice(0, 4);
        setArticles(moreArticles);
      });
    };
  }, [blogTags, slug])
  
  return (
    <section className="more padding--all">
    <div className="section-title">More Articles</div>
    <div className="articles__content">
      {Array.isArray(articles) && articles.map((article)=>(
        <div key={article.slug} className="article">
          <a href={`/blog/${article.slug}`}  className="article__title">
            {article.title}
          </a>
          <div className="article__tags">
            {article.tags.map((tag)=>(
              <Tag key={tag} tag_name={tag} chosenTags={chosenTags} setChosenTags={setChosenTags} />
            ))}
          </div>
        </div>
      ))}
        {/* {data} */}

        {/* <div className="article">
          <a href="#" className="article__title">
            How to use Django REST Framework to develope a website 1
          </a>
          <div className="article__tags">
            <a href="'#" className="tag">Python</a>
            <a href="'#" className="tag">Django</a>
          </div>
        </div>

        <div className="article">
          <a href="#" className="article__title">
            How to use sklearn FeatureUnion and automl
          </a>
          <div className="article__tags">
            <a href="'#" className="tag">Python</a>
            <a href="'#" className="tag">Machine Learning</a>
            <a href="'#" className="tag">sklearn</a>
          </div>
        </div>

        <div className="article">
          <a href="#" className="article__title">
            How I developed my personal website with React.js, SCSS and Django
            REST Framework
          </a>
          <div className="article__tags">
            <a href="'#" className="tag">Python</a>
            <a href="'#" className="tag">Django</a>
            <a href="'#" className="tag">SCSS</a>
            <a href="'#" className="tag">React</a>
            <a href="'#" className="tag">JS</a>
          </div>
        </div>

        <div className="article">
          <a href="#" className="article__title">
            Miao Miao Miao
          </a>
          <div className="article__tags">
            <a href="'#" className="tag">JS</a>
          </div>
        </div> */}
      </div>

    </section>
  );
};

export default More;
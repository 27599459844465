import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './header.scss';

const Header = ({light, setLight}) => {
  const navigate = useNavigate();
  
  const searchInput = () => {   
    const searchStr = document.getElementById('searchTxt').value;
    navigate('/bloghome/?q=' + searchStr); 
    window.location.reload();
  }

  const enterSearchInput = (e) => {
    if (e.key === 'Enter') searchInput();
  }

  const [sideOpen, setSideOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const control_overflow = () => {
    if (sideOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'visible';
    }
  }

  useEffect(control_overflow, [sideOpen]);

  useEffect(() => {
    const hide_for_large = () => {
      if (window.innerWidth >= 768) {setSideOpen(false);}
    };

    // const scrollEvent = () => setScrolled(true);

    window.addEventListener("resize", hide_for_large);
    // window.addEventListener("scroll", scrollEvent);

    return () => {
      window.removeEventListener('resize', hide_for_large);
      // window.removeEventListener('scroll', scrollEvent);
    };
  }, []);

  return (
    <header className={ sideOpen ? 'sideopen' : null }>
      {/* <div className={ scrolled ? 'navout-border': 'navout' }> */}
      <div className='navout'>

        <nav className='nav padding--x max-width'>
          
          <div className='nav__links'>
            <a href="/">Home</a>
            <a href="/bloghome">Blog</a>
            <a href="/contact">Contact</a>
            <a href="https://ko-fi.com/rndev">
              <img src="https://storage.ko-fi.com/cdn/kofi_stroke_cup.svg"  alt="" width="35"/>
            </a>
          </div>

          <div className='nav__toggle' onClick={() => {
            setSideOpen(!sideOpen);
          }}>
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div className='nav__logo'>
            <a href="/" className="nav__logo__title"><span>R</span>N</a>
            <div className="nav__logo__theme" onClick={() => {
              setLight(!light);
            }}>
              {light ? 'Dark': 'Light'} Theme
            </div>
          </div>

          <div className='nav__search'>
            <input type="text" id='searchTxt' onKeyDown={enterSearchInput}/>
            <button type="button" onClick={searchInput}>
              <i className="fa-solid fa-magnifying-glass fa-sm"></i>
            </button>
          </div>

        </nav>
      </div>
      
      <div className='sidemenu'>
        <a href="/">Home</a>
        <a href="/bloghome">Blog</a>
        <a href="/contact">Contact</a>
        <a href="https://ko-fi.com/rndev">
          <img src="https://storage.ko-fi.com/cdn/kofi_stroke_cup.svg" alt="" width="35"/>
        </a>
      </div>
    </header>
  )
};

export default Header;
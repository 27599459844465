import axiosInstance from '../axios';
import { useState, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import './contactform.scss';

const ContactForm = () => {
  const [sent, setSent] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  
  const captchaRef = useRef(null);

  const sendMsg = (e) => {
    e.preventDefault();
    if (captchaRef) {
      const token = captchaRef.current.getValue();
      captchaRef.current.reset();
      const contactInfo = {
        name: name,
        email: email,
        message: msg,
        token: token
      }

    axiosInstance.post('contact/', contactInfo)
      .then(
        ()=>setSent(true)
      );
    }
  };

  return (
    <section className='wrapper'>
      <form className={`letter ${sent ? 'sent' : null}`} onSubmit={sendMsg}>
        
        <h1>CONTACT ME</h1>
        <textarea id="contact-message" placeholder='Your message' onInput={e=>setMsg(e.target.value)}></textarea>

        <div className='side'>
          <input type="text" id="contact-name" placeholder='Your name' onInput={e=>setName(e.target.value)}/>
        {/* </div> */}
        {/* <div className='side'> */}
          <input type="email" id="contact-email" placeholder='Your email' onInput={e=>setEmail(e.target.value)}/>
        {/* </div> */}
        {/* <div className="side"> */}
          <div className="side__bottom">
            <ReCAPTCHA className='captcha' sitekey={process.env.REACT_APP_SITE_KEY} theme='dark' ref={captchaRef} />
            <button type='submit' id='send'>SEND</button>
          </div>
        </div>
        
      </form>
    </section>
  );
};

export default ContactForm;
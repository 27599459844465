import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Header, ContactForm, Footer } from '../components';
import './contact.scss';

const Contact = () => {
  return (
    <HelmetProvider>
      <div className="contact">
        <Helmet>
          <title>RNDevBlog | Contact</title>
        </Helmet>
        <Header />
        <main className='contact__main'>
          <ContactForm />
        </main>
        <Footer />
      </div>
    </HelmetProvider>
  )
};

export default Contact;


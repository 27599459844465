import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axios';
import { Tag, Page, PageChange } from '../components';
import './bloglist.scss';


const BlogList = ({ chosenTags, setChosenTags, curUrl, pageInfo }) => {
  const navigate = useNavigate();

  const clearSearch = () => {
    navigate('');
    window.location.reload();
  }

  const [posts, setPosts] = useState([]);
  const [pnum, setPnum] = useState(0);
  
  // note to reader what they are searching for
  let note = <div className='bloglist__search'></div>;
  if (pageInfo.search) {
    note = (
      <div className='bloglist__search'>
        <p className='bloglist__search__msg'>YOU'RE LOOKING FOR</p>
        <p className='bloglist__search__item'>
          { pageInfo.search } 
          <span> </span>
          <span className='bloglist__search__clear' onClick={clearSearch}>&#10005;</span>
        </p>
      </div>
    );
  }

  useEffect(() => {
    let backendUrl = 'posts/';
    if (curUrl && curUrl.includes('?q=')){ backendUrl = 'search/'; };

    axiosInstance.get(backendUrl + curUrl)
    .then((res)=>{
      setPosts(res.data.results); 
      setPnum(res.data.num_pages);
    })
    .catch(()=>navigate('/'));
    
  }, [curUrl, navigate]);

  return (
    <section className="bloglist">
      {note}
      {/* <div className="bloglist__none">
        <p>...but no results were found.</p>
      </div> */}

      <div className="bloglist__content">
        
        {Array.isArray(posts) && posts.map((post) => (
            <div key={post.slug} className="bloglist__content__article">
              <a href={`/blog/${post.slug}`}  className="bloglist__content__article__title">
                {post.title}
              </a>
              <div className="bloglist__content__article__tags">
                {post.tags && post.tags.map((tag)=><Tag key={tag} tag_name={tag} chosenTags={chosenTags} setChosenTags={setChosenTags} />)}
              </div>
            </div>
          ))}

      </div>

      <div className="bloglist__pages">
        <PageChange forward={false} pnum={pnum} curPage={pageInfo.page} curUrl={curUrl} />
        {
        Array.from({length: pnum}, (item, index) => 
          <Page thisPage={index+1} curPage={pageInfo.page} curUrl={curUrl} key={index+1} />
        ) 
        }
        <PageChange forward={true} pnum={pnum} curPage={pageInfo.page} curUrl={curUrl} />
      </div>

    </section>
  );
};

export default BlogList;
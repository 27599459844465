import './footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__content padding--x max-width">
        <div className='footer__content__title'>
          <a href='/'><span>R</span>N</a>
          <h1>DEV <span>BLOG</span></h1>
        </div>
        <div className="footer__content__links">
          <a href="/">Home</a>
          <a href="/bloghome">Blog</a>
          <a href="/contact">Contact</a>
          <a href="https://ko-fi.com/rndev">
            <img src="https://storage.ko-fi.com/cdn/kofi_stroke_cup.svg" alt="" width="35"/>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
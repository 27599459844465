
import SyntaxHighlighter from 'react-syntax-highlighter';
import { srcery } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Interweave } from 'interweave';
import { Tag } from '../components';


import './blogcontent.scss';

const BlogContent = ({ data, chosenTags, setChosenTags }) => {
   
  const transform = (node, children) => {
      if (node.nodeName === 'SYNTAXHIGHLIGHTER') {
        // strip {` and `}, then remove the first \n to avoid the first empty line
        const content = children[0].replace(/`}|{`/g, "").replace("\n", "");
        return <SyntaxHighlighter language={node.attributes.language.value} style={srcery} wrapLongLines={true}>
                  {content}
               </SyntaxHighlighter>;
      }
    };

  return (
    <section className="blogcontent padding--all">
      <h1 className='blogcontent__title'>{data.title}</h1>
      <div className='blogcontent__tags'>
        {data.tags ? data.tags.map(tag=><Tag key={tag} tag_name={tag} chosenTags={chosenTags} setChosenTags={setChosenTags} />): null}
      </div>

      <div className="blogcontent__content">
        <Interweave content={data.content} transform={transform}/>
      </div>

    </section>
  );
};

export default BlogContent;

import { Helmet, HelmetProvider } from 'react-helmet-async';
import React, { useState } from 'react';
import { Header, Hero, Tags, Articles, Footer } from '../components';

import './home.scss';

const Home = () => {
  const [chosenTags, setChosenTags] = useState(new Set());

  return (
    <HelmetProvider>
      <div className='home'>
        <Helmet>
          <title>RNDevBlog | Home</title>
        </Helmet>
        <Header />
        <Hero />
        <main className="home__main">
          <Tags chosenTags={chosenTags} setChosenTags={setChosenTags} />
          <Articles chosenTags={chosenTags} setChosenTags={setChosenTags} section='Featured'/>
          <Articles chosenTags={chosenTags} setChosenTags={setChosenTags} section='Latest'/>
        </main>

        <Footer />
      </div>
    </HelmetProvider>
  )
};

export default Home;


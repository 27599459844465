import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Header, BlogContent, Tags, TOC, More, Footer } from '../components';
import axiosInstance from '../axios';
import './blog.scss';

const Blog = () => {
  const navigate = useNavigate();
  const [chosenTags, setChosenTags] = useState(new Set());
  const { slug } = useParams();
  
  const [light, setLight] = useState(
    localStorage.getItem('light-theme') === 'true'
  );

  const [datafetched, setDatafetched] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {   
    axiosInstance.get(`posts/${slug}/`)
      .then(repos => {setData(repos.data)})
      .then(() => {setDatafetched(true)})
      .catch(() => navigate('/'));
  }, [setData, slug, navigate]);

  useEffect(() => {
    localStorage.setItem('light-theme', JSON.stringify(light));
  }, [light]);

  if(data) {
    return (
      <HelmetProvider>
        <div className={`blog ${light ? 'light': null}`}>
          <Helmet>
            <title>{data.title}</title>
          </Helmet>
          <Header light={light} setLight={setLight}/>
          <main className="blog__main">
            <div className="blog__main__current">
              <TOC isDatafetched={datafetched} />
              <BlogContent data={data} chosenTags={chosenTags} setChosenTags={setChosenTags} />
              <Tags chosenTags={chosenTags} setChosenTags={setChosenTags} />
            </div>
            <More blogTags={data.tags} slug={data.slug}/>
          </main>

          <Footer />
        </div>
      </HelmetProvider>
    );
  } else {
    return navigate('/');
  }
};

export default Blog;





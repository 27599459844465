import { useNavigate } from 'react-router-dom';
import './tag.scss';

const Tag = ({ tag_name, chosenTags, setChosenTags }) => {
  const navigate = useNavigate();

  const filterTags = () => {
    if (chosenTags.has(tag_name)) { setChosenTags(chosenTags.delete(tag_name)); }
    else { setChosenTags(chosenTags.add(tag_name)); };
    
    const tagStr = [...chosenTags].join('&tags=');
    if (tagStr.length > 0) { 
      navigate('/bloghome/?tags=' + tagStr); 
    } else { 
      navigate(''); 
    };

    window.location.reload();
  }

  return (
    <div className={`tag ${ chosenTags && chosenTags.has(tag_name) ? 'chosen': null}`} onClick={filterTags}>
      {tag_name}
    </div>
  );
};

export default Tag;
import { useNavigate } from 'react-router-dom';

const PageChange = ({ forward, pnum, curPage, curUrl }) => {
  const navigate = useNavigate();

  const changePage = () => {

    // set new page
    let newPage = curPage;
    if (forward && (curPage < pnum)) {
      newPage = curPage + 1;
    } else if ((!forward) && (curPage > 1)) {
      newPage = curPage - 1;
    }

    // set new url
    let newUrl = '';
    if(!curUrl) {
      newUrl = `?page=${newPage}`;
    } else if (!curUrl.includes('page=')) {
      newUrl = curUrl + `&page=${newPage}`;
    } else {
      newUrl = curUrl.replace(`page=${curPage}`, `page=${newPage}`);
    }
    
    // navigate to new url 
    navigate(newUrl);
    window.location.reload();
  };
  
  return (
    <div className={"bloglist__pages__link"} onClick={changePage}>
      { forward ? '>' : '<' }
    </div>
  )
};

export default PageChange;
import { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import { Tag } from '../components';
import './articles.scss';

const Articles = ({ chosenTags, setChosenTags, section }) => {
  const [posts, setPosts] = useState([]);
  let sub_url = '';
  if (section === 'Featured'){ sub_url = '?tags=★ Featured' };

  useEffect(() => {  
    axiosInstance.get('posts/' + sub_url).then((res)=>{
      setPosts(res.data.results && res.data.results.slice(0, 8)); 
    });
  }, [sub_url]);

  return (
    <section className={`articles padding--all ${section.toLowerCase()}`}>
      <div className="section-title">
        {section} <a href={"/bloghome" + sub_url} className='section-title__more'> &#187; More</a>
      </div>

      <div className="articles__content">

        {Array.isArray(posts) && posts.map((post) => (
          <div key={post.slug} className="article">
            <a href={`blog/${post.slug}`}  className="article__title">
              {post.title}
            </a>
            <div className="article__tags">
              {post.tags && post.tags.map((tag)=><Tag key={tag} tag_name={tag} chosenTags={chosenTags} setChosenTags={setChosenTags} />)}
            </div>
          </div>
         ))}

      </div>
    </section>
  );
};

export default Articles;
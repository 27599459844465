import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import { Home, BlogHome, Contact, Blog } from './pages';

// import './prism.css';
import './index.scss';

ReactGA.initialize("G-LV0KWVLGG8");

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/bloghome' element={<BlogHome />} />
        <Route path='/blog/:slug' element={<Blog />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </Router>
  </React.StrictMode>
);





import './hero.scss';

const Hero = () => {
  return (
    <div className='hero'>
      <h1>DEV <span>BLOG</span></h1>
    </div>
  );
};

export default Hero;
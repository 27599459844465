import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { Header, BlogList, Tags, Footer } from '../components';
import './bloghome.scss';

const BlogHome = () => {
  const [chosenTags, setChosenTags] = useState(new Set());
  const [pageInfo, setPageInfo] = useState({page: 1, search: ''});
  const curUrl = window.location.search;

  useEffect(() => {
    // this is to make sure page reload when hitting browser back and forward button
    window.onpopstate = () => {
      window.location.reload();
    }

    // gether url related info
    const params = new URLSearchParams(curUrl);
    const search_param = params.get('q');

    let page_param = params.get('page');
    if (!page_param) {
      page_param = 1;
    } else {
      page_param=parseInt(page_param);
    }; 

    setPageInfo({page: page_param, search: search_param});

    // gather all tags in the url
    setChosenTags(new Set(params.getAll('tags')));

  }, [curUrl]);

  return (
    <HelmetProvider>
      <div className="bloghome">
        <Helmet>
          <title>RNDevBlog | Blog Home</title>
        </Helmet>
        <Header />
        <main className="bloghome__main">
          <Tags chosenTags={chosenTags} setChosenTags={setChosenTags} />
          <BlogList chosenTags={chosenTags} setChosenTags={setChosenTags} curUrl={curUrl} pageInfo={pageInfo} />
        </main>

        <Footer />
      </div>
    </HelmetProvider>
  )
};

export default BlogHome;





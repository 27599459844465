import { useNavigate } from 'react-router-dom';

const Page = ({ thisPage, curPage, curUrl }) => {
  const navigate = useNavigate();

  const changePage = () => {
    // set new url
    let newUrl = '';
    if(!curUrl) {
      newUrl = `?page=${thisPage}`;
    } else if (!curUrl.includes('page=')) {
      newUrl = curUrl + `&page=${thisPage}`;
    } else {
      newUrl = curUrl.replace(`page=${curPage}`, `page=${thisPage}`);
    };
    
    // navigate to new url
    navigate(newUrl);
    window.location.reload();
  };
  
  return (
    <div className={curPage === thisPage ? "bloglist__pages__link cur" : "bloglist__pages__link"} onClick={changePage}>
      {thisPage}
    </div>
  )
};

export default Page;
import axios from 'axios';

const baseURL = `https://rndevblog.com/api/`;
// const baseURL = `http://127.0.0.1:8000/api/`;

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 10000   
});

export default axiosInstance;
import { useEffect, useState, useRef } from 'react';
import './toc.scss';

const getNestedHeadings = (headingElements) => {
  const nestedHeadings = [];

  headingElements.forEach((heading) => {
    const { innerText: title, id } = heading;

    if (heading.nodeName === 'H2') {
      nestedHeadings.push({id, title, items: []});
    } else if (heading.nodeName === 'H3' && nestedHeadings.length > 0) {
      nestedHeadings[nestedHeadings.length-1].items.push({id, title});
    }

  });    

  return nestedHeadings;
};

const useHeadingData = ({isDatafetched}) => {
  const [nestedHeadings, setNestHeadings] = useState([]);

  useEffect(() => {
    const headingElements = Array.from(
      document.querySelectorAll('h2, h3')
    );
    
    const newNestedHeadings = getNestedHeadings(headingElements);
    setNestHeadings(newNestedHeadings);
  }, [isDatafetched]);

  return {nestedHeadings};
};

const Headings = ({ headings, activeId }) => (
  <ul>
    {headings.map((heading) => (
      <li key={heading.id} className={heading.id === activeId ? 'active': ''}>
        <a 
          href={`#${heading.id}`}
          onClick={(e) => {
            e.preventDefault();
            document.getElementById(heading.id).scrollIntoView({behavior: 'smooth'});
          }}
        >
          {heading.title}
        </a>
        {heading.items.length > 0 && (
          <ul>
            {heading.items.map((child) => (
              <li key={child.id} className={child.id === activeId ? 'active': ''}>
                <a 
                  href={`#${child.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector(`#${child.id}`).scrollIntoView({behavior: 'smooth'});
                  }}
                >
                  {child.title}
                </a>
              </li>
            ))}
          </ul>
        )}
      </li>
    ))}
  </ul>
);

const useIntersectionObserver = ({isDatafetched, setActiveId}) => {
  const headingElementsRef = useRef({});

  useEffect(() => {
    const callback = (headings) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement;
        return map;
      }, headingElementsRef.current);

      // console.log('headingElementsRef', headingElementsRef.current);
      const visibleHeadings = [];
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key];
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
      });

      // console.log('visibleHeadings', visibleHeadings);

      const getIndexFromId = (id) => headingElements.findIndex((heading) => heading.id === id);

      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id);
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
        );
        setActiveId(sortedVisibleHeadings[0].target.id);
      }
    };

    const observer = new IntersectionObserver(callback, {rootMargin: '-144px 0px -70% 0px'});
    const headingElements = Array.from(document.querySelectorAll('h2, h3'));
    headingElements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, [setActiveId, isDatafetched]);
};

const TOC = ({isDatafetched}) => {
  const [activeId, setActiveId] = useState();
  const { nestedHeadings } = useHeadingData({isDatafetched});
  useIntersectionObserver({isDatafetched, setActiveId});
  return (
    <section className="toc-out">
      <nav className="toc padding--all">
        <div className="section-title">Table of Contents</div>
        <div className="toc__content">
          <Headings headings={nestedHeadings} activeId={activeId} />
        </div>
      </nav>
    </section>
  );
};

export default TOC;